$(document).ready (function(){
  $("#addbtn").click(function showAlert() {
    $("#alert").fadeTo(2000, 500).slideUp(500, function(){
      $("#alert").slideUp(500);
    });
  });
  $("#delbtn").click(function showAlert() {
    $("#alert").fadeTo(2000, 500).slideUp(500, function(){
      $("#alert").slideUp(500);
    });
  });
});
